import React from 'react'
import PropTypes from 'prop-types'

import Episode from './episode'
import styles from './episodes.css'
import { E2E_SELECTORS } from '../../../../e2e-selectors'

function Episodes({
  episodes,
  playBackDetails,
  selectedEpisodeId,
  isWatchable
}) {
  return (
    <div
      className={styles.wrapper}
      data-lbx-e2e={E2E_SELECTORS.EPISODE_LIST}
      ref={(ref) => {
        if (!ref) return
        const selectedEpisode = document.getElementById(selectedEpisodeId)
        if (selectedEpisode) {
          ref.scrollLeft = selectedEpisode.offsetLeft
        } else {
          ref.scrollLeft = 0
        }
      }}
    >
      {
        episodes.map(
          episode => (
            <Episode
              key={episode.id}
              isSelected={episode.id === selectedEpisodeId}
              episode={episode}
              playback={
                playBackDetails[episode.id] &&
                playBackDetails[episode.id].playbackInfo
              }
              isWatchable={isWatchable}
            />
          )
        )
      }
    </div>
  )
}

Episodes.propTypes = {
  episodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.shape({
      uri: PropTypes.string.isRequired
    }))
  })).isRequired,
  selectedEpisodeId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  playBackDetails: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isWatchable: PropTypes.bool.isRequired
}

export default Episodes
