import { graphql } from 'react-apollo'
import { pathOr, reduce } from 'ramda'
import {
  withProps, compose, renderNothing, branch
} from 'recompose'
import PLAYBACK_INFO_QUERY from '../../graphql/queries/playback-info.gql'
import withIsAuthenticated from './is-authenticated'

const convertToDictionary = reduce((acc, item) => {
  const { contentItemId, ...playbackInfo } = item
  return {
    ...acc,
    ...{
      [contentItemId]: playbackInfo
    }
  }
}, {})

export const withPlaybackInfo = compose(
  withIsAuthenticated,

  graphql(PLAYBACK_INFO_QUERY, {
    name: 'playbackInfoQuery',
    options: ({ fromPlayer, series }) => {
      return {
        variables: {
          contentItemId: series.id
        },
        fetchPolicy: fromPlayer ? 'cache-first' : 'network-only'
      }
    },
    skip: ({ isAuthenticated }) => !isAuthenticated
  }),
  branch(
    ({ playbackInfoQuery }) => playbackInfoQuery &&
      (playbackInfoQuery.loading || playbackInfoQuery.error),
    renderNothing
  ),
  withProps(({ playbackInfoQuery }) => {
    const playBackDetails = convertToDictionary(
      pathOr([], ['playbackInfo', 'items'], playbackInfoQuery)
    )
    return {
      playBackDetails,
      selectedEpisodeId: pathOr(
        null,
        ['playbackInfo', 'episodeToPlay'],
        playbackInfoQuery
      ),
      isWatchable: pathOr(
        false,
        ['playbackInfo', 'watchable'],
        playbackInfoQuery
      )
    }
  })
)
