import { graphql } from 'react-apollo'
import FeatureFlagQuery from '../../graphql/queries/feature-flags.gql'

const withFeatureFlag = (...featureNames) => graphql(FeatureFlagQuery, {
  name: 'featureFlags',
  options: {
    variables: {
      featureNames
    }
  },
  props: ({ ownProps, featureFlags: { loading, error, featureflags } }) => {
    return {
      ...ownProps,
      featureflags,
      loading,
      error
    }
  }
})

export default withFeatureFlag
