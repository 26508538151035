import React from 'react'
import classNames from 'classnames'
import { T } from 'ramda'
import PropTypes from 'prop-types'
import VerticalTiles from './vertical-tiles'
import styles from './vertical-recommendations.css'
import { YOU_MAY_ALSO_LIKE_ITEM_LIMIT } from '../../constants'

const YMAL_TITLE = 'You May Also Like'

export function VerticalRecommendations({
  series, theme, addToMyList, removeFromMyList, isContentItemOnMyList
}) {
  return (
    <div className={classNames(styles.rightColumnWrapper)}>
      <div className={styles.rightColumnTitle}>
        <div className={styles.title}>
          {YMAL_TITLE}
        </div>
      </div>
      <div className={styles.rightColumnList}>
        <VerticalTiles
          tiles={series.recommendedTiles.slice(0, YOU_MAY_ALSO_LIKE_ITEM_LIMIT)}
          theme={theme}
          listTitle={YMAL_TITLE}
          isContentItemOnMyList={isContentItemOnMyList}
          addToMyList={addToMyList}
          removeFromMyList={removeFromMyList}
        />
      </div>
    </div>
  )
}

VerticalRecommendations.propTypes = {
  series: PropTypes.oneOfType([PropTypes.object]).isRequired,
  theme: PropTypes.string.isRequired,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  isContentItemOnMyList: PropTypes.func
}

VerticalRecommendations.defaultProps = {
  addToMyList: T,
  removeFromMyList: T,
  isContentItemOnMyList: T
}

export default VerticalRecommendations
