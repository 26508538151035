import React from 'react'
import PropTypes from 'prop-types'

import styles from './progress.css'

function Progress({
  played,
  total
}) {
  const progressPercentage = (played / total) * 100

  return (
    <div className={styles.progressWrapper}>
      <div
        className={styles.progress}
        style={{ width: `${progressPercentage}%` }}
      />
    </div>
  )
}

Progress.propTypes = {
  played: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

export default Progress
