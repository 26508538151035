import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { length, pathOr } from 'ramda'

import Seasons from '../../containers/series/seasons'
import Trailers from '../shared/trailers'
import Recommendations from '../shared/recommendations'
import VerticalRecommendation from '../vertical-tiles/vertical-recommendations'
import { getContentParentalRatingDisplay, getFirstTrailer } from '../../lib/utils'
import { isSmallerThan480 } from '../../lib/screen'
import { SCREEN_SIZE_BREAKPOINTS } from '../../constants'

import styles from './series-list.css'

const TABS = {
  episodes: 'episodes',
  trailers: 'trailers',
  recommendedTiles: 'recommendedTiles'
}
const TABS_LABELS = {
  episodes: 'EPISODES',
  trailers: 'TRAILERS',
  recommendedTiles: 'YOU MAY ALSO LIKE'
}

const TABS_LABELS_SAMLL_SCREEN = {
  episodes: 'EPISODES',
  trailers: 'TRAILERS',
  recommendedTiles: 'RELATED'
}

function SeriesList({
  series,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList,
  theme,
  setSelectedTab,
  selectedTab,
  screenWidth
}) {
  if (!series || !length(series.seasons)) {
    return null
  }

  const isShowVerticalRecommendation = series.recommendedTiles && series.recommendedTiles.length > 0
  const isShowTrailer = getFirstTrailer(series)

  const isShowYouMayAlsoLikeTab = screenWidth <= SCREEN_SIZE_BREAKPOINTS.MIN_768 && isShowVerticalRecommendation
  if (!isShowYouMayAlsoLikeTab && TABS.recommendedTiles === selectedTab) setSelectedTab(TABS.episodes)

  const isSamllScreen = isSmallerThan480({ screenWidth })

  return (
    <>
      <div
        className={classNames(styles.seriesListWrapper, {
          [styles.fullWidth]: !isShowVerticalRecommendation
        })}
      >
        <div className={styles.seriesListTabWrapper}>
          <div
            className={classNames(styles.tab, {
              [styles.active]: TABS.episodes === selectedTab
            })}
            onClick={() => { setSelectedTab(TABS.episodes) }}
          >
            {isSamllScreen ? TABS_LABELS_SAMLL_SCREEN[TABS.episodes] : TABS_LABELS[TABS.episodes]}
          </div>
          {
            isShowTrailer && (
              <div
                className={classNames(styles.tab, {
                  [styles.active]: TABS.trailers === selectedTab
                })}
                onClick={() => { setSelectedTab(TABS.trailers) }}
              >
                {isSamllScreen ? TABS_LABELS_SAMLL_SCREEN[TABS.trailers] : TABS_LABELS[TABS.trailers]}
              </div>
            )
          }
          {
            isShowYouMayAlsoLikeTab && (
              <div
                className={classNames(styles.tab, {
                  [styles.active]: TABS.recommendedTiles === selectedTab
                })}
                onClick={() => { setSelectedTab(TABS.recommendedTiles) }}
              >
                {isSamllScreen ? TABS_LABELS_SAMLL_SCREEN[TABS.recommendedTiles] : TABS_LABELS[TABS.recommendedTiles]}
              </div>
            )
          }
        </div>

        {
          TABS.episodes === selectedTab && (
            <Seasons
              series={series}
              seasons={series.seasons}
              // recommendedTiles={series.recommendedTiles}
              // theme={theme}
              addToMyList={addToMyList}
              removeFromMyList={removeFromMyList}
              isContentItemOnMyList={isContentItemOnMyList}
              rating={getContentParentalRatingDisplay(series)}
              reason={pathOr('', ['rating', 'reason'], series)}
              advisories={pathOr('', ['rating', 'advisories'], series)}
              screenWidth={screenWidth}
            />
          )
        }
        {
          TABS.trailers === selectedTab && (
            <Trailers
              contentItem={series}
              theme={theme}
              screenWidth={screenWidth}
            />
          )
        }
        {
          TABS.recommendedTiles === selectedTab && (
            <Recommendations
              tiles={series.recommendedTiles}
              theme={theme}
              listTitle="You May Also Like"
              isContentItemOnMyList={isContentItemOnMyList}
              addToMyList={addToMyList}
              removeFromMyList={removeFromMyList}
            />
          )
        }
      </div>
      {
        isShowVerticalRecommendation && (
          <VerticalRecommendation
            series={series}
            theme={theme}
            isContentItemOnMyList={isContentItemOnMyList}
            addToMyList={addToMyList}
            removeFromMyList={removeFromMyList}
          />
        )
      }
    </>
  )
}

SeriesList.propTypes = {
  series: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isContentItemOnMyList: PropTypes.func.isRequired,
  addToMyList: PropTypes.func.isRequired,
  removeFromMyList: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string,
  screenWidth: PropTypes.number
}

SeriesList.defaultProps = {
  selectedTab: TABS.episodes,
  screenWidth: window.innerWidth
}

export default SeriesList
