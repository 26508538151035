import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classNames from 'classnames'
import ReactSVG from 'react-svg'
import { pathOr } from 'ramda'

import Imgix from '../imgix'
import PlayButton from '../../containers/play-button/details-play-button'
import { getDurationTextFromMinutes } from '../../lib/date-time'
import UpcomingEpisode from '../shared/upcoming-episode'
import { isSmallerThan1024 } from '../../lib/screen'

import styles from './episode.css'

import EPISODE_FRAGMENT from '../../../graphql/fragments/episode.gql'
import PLAYBACK_INFO_FRAGMENT from '../../../graphql/fragments/playback-info.gql'
import arrow from '../../../images/arrow-up-dark.svg'

function renderForPlayerOnTablet(
  highlight,
  episode,
  isOpen,
  heightForTablet,
  calculateFullHeightForTablet,
  imageUri,
  playbackInfo,
  setIsOpen,
  toScroll
) {
  return (
    <div
      className={classNames(
        styles.episode,
        styles.episodeMobile,
        styles.episodePlayer,
        { [styles.highlight]: highlight }
      )}
      id={`episode-${episode.id}`}
      ref={ref => highlight && toScroll(ref)}
    >
      <div className={styles.playerAndTitle}>
        <div
          className={classNames(
            styles.text,
            styles.textTablet,
            styles.titleAndArrow
          )}
          onClick={setIsOpen}
        >
          <p className={styles.episodeTitle}>
            {episode.episodeNumber}
            .
            {' '}
            {episode.title}
          </p>
          <ReactSVG
            src={arrow}
            beforeInjection={(svg) => {
              svg.setAttribute('class', classNames(
                styles.arrow,
                { [styles.arrowDown]: !isOpen }
              ))
            }}
          />
        </div>
      </div>

      <div className={styles.episodeContentTablet}>
        <span
          style={{ height: heightForTablet }}
          ref={calculateFullHeightForTablet}
          className={styles.episodeSpan}
        >
          <div
            className={classNames(
              styles.imageWrapper,
              styles.imageWrapperforTablet,
              { [styles.imgOverlay]: episode.upcoming || episode.isComingSoon }
            )}
          >
            <Imgix src={imageUri} recipe="series" />
            {
              episode.upcoming || episode.isComingSoon
                ? (
                  <UpcomingEpisode
                    startDate={episode.start}
                    mobile
                  />
                )
                : (
                  <div className={styles.playWrapper}>
                    <div
                      className={
                        classNames(styles.ctaContainer, styles.smallPlay, styles.playerSmallPlay)
                      }
                    >
                      <PlayButton
                        className={styles.smallPlay}
                        ctaClass={styles.tabletLabel}
                        ctaTimeClass={styles.tabletTimeLabel}
                        contentItem={episode}
                        contentItemId={episode.id}
                        playbackInfo={playbackInfo}
                      />
                    </div>
                  </div>
                )
            }
          </div>
          <div className={styles.metadataTablet}>
            <div className={styles.durationTablet}>
              <span>
                {getDurationTextFromMinutes(episode.duration)}
              </span>
            </div>
          </div>
        </span>
      </div>

    </div>
  )
}

function renderForShowPage(
  playerCss,
  highlight,
  episode,
  imageUri,
  playbackInfo,
  isOpen,
  height,
  calculateFullHeight,
  setIsOpen,
  toScroll
) {
  return (
    <div
      className={classNames(
        styles.episode,
        styles.episodeMobile,
        {
          [styles.showPagePadding]: !playerCss,
          [styles.episodePlayer]: playerCss,
          [styles.highlight]: highlight
        }
      )}
      id={`episode-${episode.id}`}
      ref={ref => highlight && toScroll(ref)}
    >
      <div className={styles.playerAndTitle}>
        <div
          className={classNames(
            styles.imageWrapper,
            {
              [styles.imageWrapperfromPlayer]: playerCss,
              [styles.imgOverlay]: episode.upcoming || episode.isComingSoon
            }
          )}
        >
          <Imgix src={imageUri} recipe="series" />
          {
            episode.upcoming || episode.isComingSoon
              ? (
                <UpcomingEpisode
                  startDate={episode.start}
                  mobile
                />
              )
              : (
                <div className={styles.playWrapper}>
                  <div className={classNames(styles.ctaContainer, styles.playerMediumPlay)}>
                    <PlayButton
                      className={styles.smallPlay}
                      ctaClass={styles.webLabel}
                      contentItem={episode}
                      contentItemId={episode.id}
                      playbackInfo={playbackInfo}
                    />
                  </div>
                </div>
              )
          }
        </div>

        <div className={styles.text}>
          <div className={styles.titleAndArrow} onClick={setIsOpen}>
            <p className={styles.episodeTitle}>
              {episode.episodeNumber}
              .
              {' '}
              {episode.title}
            </p>
            <ReactSVG
              src={arrow}
              beforeInjection={(svg) => {
                svg.setAttribute('class', classNames(
                  styles.arrow,
                  styles.webArrow,
                  { [styles.arrowDown]: !isOpen }
                ))
              }}
            />
          </div>
          <div className={styles.metadata}>
            <span className={styles.duration}>
              {getDurationTextFromMinutes(episode.duration)}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.text}>
        <div className={styles.episodeContent}>
          <p
            className={classNames(styles.description, styles.mobileDescription)}
            style={{ height }}
            ref={calculateFullHeight}
          >
            {episode.description || episode.summary}
          </p>
        </div>
      </div>
    </div>
  )
}

function EpisodeMobile({
  episode,
  playbackInfo,
  highlight,
  setIsOpen,
  isOpen,
  height,
  heightForTablet,
  calculateFullHeight,
  calculateFullHeightForTablet,
  playerCss,
  screenWidth,
  toScroll
}) {
  const imageUri = pathOr('', ['images', '0', 'uri'], episode)
  const isTablet = isSmallerThan1024({ screenWidth })
  return (
    <div>
      {
        playerCss && isTablet ? renderForPlayerOnTablet(
          highlight,
          episode,
          isOpen,
          heightForTablet,
          calculateFullHeightForTablet,
          imageUri,
          playbackInfo,
          setIsOpen,
          toScroll
        )
          : renderForShowPage(
            playerCss,
            highlight,
            episode,
            imageUri,
            playbackInfo,
            isOpen,
            height,
            calculateFullHeight,
            setIsOpen,
            toScroll
          )
      }
    </div>
  )
}

EpisodeMobile.propTypes = {
  episode: propType(EPISODE_FRAGMENT).isRequired,
  playbackInfo: propType(PLAYBACK_INFO_FRAGMENT),
  highlight: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  heightForTablet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  calculateFullHeight: PropTypes.func.isRequired,
  calculateFullHeightForTablet: PropTypes.func.isRequired,
  toScroll: PropTypes.func.isRequired,
  playerCss: PropTypes.bool.isRequired,
  screenWidth: PropTypes.number
}

EpisodeMobile.defaultProps = {
  playbackInfo: null,
  highlight: false,
  height: '',
  heightForTablet: '',
  screenWidth: 0
}

export default EpisodeMobile
