import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './selector.css'

function LinkSelectorKids({
  seasons,
  seasonSelected,
  setSeasonSelected
}) {
  return (
    <>
      {
        seasons.map((season) => {
          return (
            <button
              key={season.value}
              className={classNames(
                styles.option,
                {
                  [styles.selected]: seasonSelected === season.value
                }
              )}
              onClick={() => setSeasonSelected(season.value)}
            >
              {season.name}
            </button>
          )
        })
      }
    </>
  )
}

LinkSelectorKids.propTypes = {
  seasons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number
    }).isRequired
  ).isRequired,
  seasonSelected: PropTypes.number.isRequired,
  setSeasonSelected: PropTypes.func.isRequired
}

export default LinkSelectorKids
