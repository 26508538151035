import {
  find,
  flatten,
  isEmpty,
  path,
  pathOr,
  pluck,
  propEq
} from 'ramda'
import { graphql } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import {
  branch,
  compose,
  defaultProps,
  lifecycle,
  renderNothing,
  withHandlers,
  withProps,
  withState,
  withStateHandlers
} from 'recompose'

import Seasons from '../../components/series/seasons'
import withIsAuthenticated from '../../hoc/is-authenticated'

import PLAYBACK_INFO_QUERY from '../../../graphql/queries/playback-info.gql'
import {
  navigationGa,
  seasonHouseId
} from '../../lib/analytics/ga'
import { customDimensions } from '../../lib/analytics/custom-dimensions'

export const enhance = compose(
  defaultProps({ navigationGaFunction: navigationGa }),
  withRouter,
  withIsAuthenticated,
  graphql(PLAYBACK_INFO_QUERY, {
    name: 'playbackInfoQuery',
    options: ({ fromPlayer, series }) => {
      return {
        variables: {
          contentItemId: series.id
        },
        fetchPolicy: fromPlayer ? 'cache-first' : 'network-only'
      }
    },
    skip: ({ isAuthenticated }) => {
      return !isAuthenticated
    }
  }),
  branch(
    ({ playbackInfoQuery }) => (
      (playbackInfoQuery && (playbackInfoQuery.loading || playbackInfoQuery.error))
    ),
    renderNothing
  ),
  withProps(({ playbackInfoQuery }) => {
    return ({
      playbackDetails: pathOr(null, ['playbackInfo'], playbackInfoQuery),
      selectedEpisodeId: pathOr(null, ['playbackInfo', 'episodeToPlay'], playbackInfoQuery)
    })
  }),
  withState('expandedIndex', 'setExpandedIndex', '0'),
  withStateHandlers(
    ({ seasons, selectedEpisodeId }) => {
      let seasonSelected
      if (selectedEpisodeId) {
        seasons.forEach((season) => {
          season.episodes.forEach((episode) => {
            if (episode.id === selectedEpisodeId) {
              seasonSelected = episode.seasonNumber
            }
          })
        })
      }

      return {
        seasonSelected: seasonSelected || pathOr(1, ['0', 'seasonNumber'], seasons)
      }
    },
    {
      setSeasonSelected: ({
        seasonSelected
      }, {
        series,
        navigationGaFunction,
        playbackDetails
      }) => (seasonNumber) => {
        let selectedEpisode = {}
        if (playbackDetails) {
          const episodeToPlay = path(['episodeToPlay'], playbackDetails)
          selectedEpisode = episodeToPlay && find(propEq('id', episodeToPlay), flatten(pluck('episodes', series.seasons)))
        }

        navigationGaFunction({
          action: 'Change Season',
          label: `${series.title} S${seasonNumber}`,
          [customDimensions.ShowTitle]: series.title,
          [customDimensions.SeasonNumber]: seasonSelected,
          [customDimensions.SeriesTitleHouseId]: series.ldId,
          [customDimensions.SeasonHouseId]:
              selectedEpisode && !isEmpty(selectedEpisode) && seasonHouseId(
                series.seasons.find(season => season.seasonNumber === selectedEpisode.seasonNumber)
              ),
          [customDimensions.EpisodeHouseId]: selectedEpisode && selectedEpisode.ldId
        })
        return {
          seasonSelected: seasonNumber
        }
      }
    }
  ),
  withStateHandlers(
    () => ({
      episodeRef: null
    }),
    {
      registerEpisodeRef: ({ episodeRef }) => (ref) => {
        if (!ref && episodeRef !== null) {
          console.warn('registerEpisodeRef should be called once. Please check containers/series/seasons.js')
          return {}
        }

        return { episodeRef: ref }
      }
    }
  ),
  lifecycle({
    componentDidMount() {
      // TODO: refactor, used SetTimeout as a temporary solution.
      setTimeout(
        () => {
          if (!this.props.episodeRef) return

          const componentToScroll = pathOr(null, ['episodeRef', 'parentNode', 'parentNode'], this.props)
          const episodeOffset = pathOr(null, ['episodeRef', 'offsetTop'], this.props)
          componentToScroll.scrollTop = episodeOffset - 80
        },
        200
      )
    },
    shouldComponentUpdate(nextProps) {
      return this.props.episodeRef !== nextProps.episodeRef ||
        this.props.expandedIndex !== nextProps.expandedIndex ||
        this.props.seasonSelected !== nextProps.seasonSelected
    }
  }),
  withHandlers({
    setOpenEpisode: ({
      setExpandedIndex,
      expandedIndex
    }) => episode => (expandedIndex === episode ? setExpandedIndex('0') : setExpandedIndex(episode))
  })
)

export default enhance(Seasons)
