import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { T } from 'ramda'
import CarouselTile from '../../containers/carousel/tile'
import { THEME_OPTIONS } from '../../constants'
import {
  slipnslideCalcTileShowCount
} from '../../lib/utils'

function VerticalTiles({
  tiles,
  theme,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList,
  listTitle
}) {
  const [tileWidth, setTileWidth] = useState(resetTileSize())
  useEffect(() => {
    window.addEventListener('resize', () => resetTileSize(setTileWidth))
    return window.removeEventListener('resize', () => resetTileSize(setTileWidth))
  })
  return renderTiles({
    tiles, addToMyList, removeFromMyList, isContentItemOnMyList, tileWidth, theme, listTitle
  })
}

export function resetTileSize(callback) {
  const slidesToShow = slipnslideCalcTileShowCount(window.innerWidth, 2)
  const containerWidth = `${1 / slidesToShow}`
  const tileWidth = (window.innerWidth - 100) * containerWidth
  return (callback && callback(tileWidth)) || tileWidth
}

function renderTiles({
  tiles,
  theme,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList,
  listTitle
}) {
  const style = {
    marginBottom: '16px'
  }
  return tiles.map((tile, index) => {
    return (
      <div key={tile.image} style={style}>
        <CarouselTile
          tile={tile}
          index={index}
          theme={theme}
          listTitle={listTitle}
          isOnMyList={isContentItemOnMyList(tile.contentItem.id)}
          addToMyList={addToMyList}
          removeFromMyList={removeFromMyList}
        />
      </div>
    )
  })
}

VerticalTiles.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tiles: PropTypes.array,
  theme: PropTypes.oneOf([THEME_OPTIONS.dark, THEME_OPTIONS.light]).isRequired,
  isContentItemOnMyList: PropTypes.func,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  listTitle: PropTypes.string.isRequired
}

VerticalTiles.defaultProps = {
  tiles: [],
  addToMyList: T,
  removeFromMyList: T,
  isContentItemOnMyList: T
}

export default VerticalTiles
