import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import {
  propOr,
  head,
  length,
  pathOr,
  merge,
  find
} from 'ramda'

import classNames from 'classnames'

import SeasonSlide from './season-slide'
import Episode from '../../containers/series/episode'
import styles from './seasons.css'

import PLAYBACK_DETAILS_FRAGMENT from '../../../graphql/fragments/playback-details.gql'
import { isIE } from '../../lib/utils'

// add 2vw for IE to match the UI, otherwise the div is misaligned
const episodeFixHeightForPlayerOnTablet = isIE() ? '16vw' : '14vw'
const episodeFullHeights = {}
const episodeFullHeightsForTablet = {}

function Seasons({
  seasons,
  setSeasonSelected,
  seasonSelected,
  playbackDetails,
  setOpenEpisode,
  expandedIndex,
  fromPlayer,
  registerEpisodeRef,
  rating,
  reason,
  advisories,
  screenWidth
}) {
  if (!length(seasons)) {
    return null
  }

  const seasonsMenu = seasons.map((season, index) => {
    const classes = classNames(styles.season, {
      [styles.seasonActive]: seasonSelected === season.seasonNumber,
      [styles.seasonPlayer]: fromPlayer,
      [styles.oneSeasonPlayer]: seasons.length <= 1
    })

    return (
      <div // eslint-disable-line jsx-a11y/no-static-element-interactions
        className={classes}
        key={season.id}
        onClick={() => setSeasonSelected(season.seasonNumber)}
        style={{ msGridColumn: index + 1 }}
        id={`season-${season.id}`}
      >
        <span>{`Season ${season.seasonNumber}`}</span>
      </div>
    )
  })

  // get the season selected item or, if doesnt exist season 1, get the first season on the list
  const fullSeasonSelected = find(season => season.seasonNumber === parseInt(seasonSelected, 10), seasons) || head(seasons)
  const playbackInfoArray = propOr([], 'items', playbackDetails)
  const episodeToPlay = propOr('', 'episodeToPlay', playbackDetails)
  const isWatchable = pathOr(false, ['watchable'], playbackDetails)

  return (
    <div className={styles.seasons}>
      <div
        className={classNames(
          {
            [styles.seasonsSelector]: !fromPlayer,
            [styles.seasonsSelectorPlayer]: fromPlayer,
            [styles.oneSeasonSelectorPlayer]: seasonsMenu.length <= 1
          }
        )}
      >
        <SeasonSlide
          seasons={seasons}
          seasonSelected={seasonSelected}
          setSeasonSelected={setSeasonSelected}
          screenWidth={screenWidth}
        />
      </div>
      <div
        className={classNames(
          styles.episodes,
          { [styles.episodesScrolling]: fromPlayer }
        )}
      >
        {
          fullSeasonSelected.episodes.map((episode) => {
            let playbackInfoItem = playbackInfoArray.find(
              playbackInfo => playbackInfo.contentItemId === episode.id
            )
            // add the series watchable field on the episode playbackinfoitem
            playbackInfoItem = merge(playbackInfoItem, {
              watchable: isWatchable
            })
            return (
              <Episode
                setIsOpen={() => setOpenEpisode(episode.id)}
                isOpen={episode.id === expandedIndex}
                key={episode.id}
                height={expandedIndex === episode.id ? episodeFullHeights[episode.id] : ''}
                calculateFullHeight={(ref) => {
                  if (!ref) return
                  if (episodeFullHeights[episode.id]) return
                  const currentHeight = ref.style.height
                  ref.style.height = 'auto'
                  episodeFullHeights[episode.id] = ref.scrollHeight
                  ref.style.height = currentHeight
                }}
                heightForTablet={expandedIndex === episode.id ? episodeFullHeightsForTablet[episode.id] : ''}
                calculateFullHeightForTablet={(ref) => {
                  if (!ref) return
                  if (episodeFullHeightsForTablet[episode.id]) return
                  const currentHeight = ref.style.height
                  ref.style.height = 'auto'
                  episodeFullHeightsForTablet[episode.id] = episodeFixHeightForPlayerOnTablet
                  ref.style.height = currentHeight
                }}
                toScroll={registerEpisodeRef}
                episode={episode}
                playbackInfo={playbackInfoItem}
                highlight={
                  episodeToPlay
                    ? episode.id === episodeToPlay
                    : episode.id === pathOr(null, ['0', 'episodes', '0', 'id'], seasons)
                }
                playerCss={fromPlayer}
                rating={rating}
                reason={reason}
                advisories={advisories}
              />
            )
          })
        }
      </div>
    </div>
  )
}

Seasons.propTypes = {
  seasons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    seasonNumber: PropTypes.number
  })).isRequired,
  seasonSelected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  setSeasonSelected: PropTypes.func.isRequired,
  playbackDetails: propType(PLAYBACK_DETAILS_FRAGMENT),
  setOpenEpisode: PropTypes.func.isRequired,
  expandedIndex: PropTypes.string.isRequired,
  fromPlayer: PropTypes.bool,
  registerEpisodeRef: PropTypes.func.isRequired,
  rating: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  advisories: PropTypes.string.isRequired,
  screenWidth: PropTypes.number
}

Seasons.defaultProps = {
  playbackDetails: null,
  fromPlayer: false,
  screenWidth: window.innerWidth
}

export default Seasons
