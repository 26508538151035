import React from 'react'
import PropTypes from 'prop-types'
import { compose, withStateHandlers, setDisplayName } from 'recompose'
import Selector from './selector'
import Episodes from './episodes'

import { withPlaybackInfo } from '../../../../hoc/withPlaybackInfo'

function SeasonsKids({
  seasons,
  playBackDetails,
  isWatchable,
  selectedEpisodeId,
  currentSeason,
  setCurrentSeason
}) {
  const seasonTitles = seasons.map((season, idx) => ({
    name: `Season ${season.title}`,
    value: idx
  }))
  const season = seasons[currentSeason]
  const { episodes } = season

  return (
    <div>
      <Selector
        seasons={seasonTitles}
        seasonSelected={currentSeason}
        setSeasonSelected={setCurrentSeason}
      />
      <Episodes
        selectedEpisodeId={selectedEpisodeId}
        episodes={episodes}
        playBackDetails={playBackDetails}
        isWatchable={isWatchable}
      />
    </div>
  )
}

SeasonsKids.propTypes = {
  seasons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      episodes: PropTypes.array
    }).isRequired
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  playBackDetails: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isWatchable: PropTypes.bool.isRequired,
  currentSeason: PropTypes.number.isRequired,
  setCurrentSeason: PropTypes.func.isRequired,
  selectedEpisodeId: PropTypes.string.isRequired
}

const enhance = compose(
  setDisplayName('SeasonsKidsContainer'),
  withPlaybackInfo,
  withStateHandlers(
    ({ seasons, selectedEpisodeId }) => {
      let currentSeason = -1
      seasons.forEach((season, seasonIdx) => {
        season.episodes.forEach(episode => {
          if (episode.id === selectedEpisodeId) {
            currentSeason = seasonIdx
          }
        })
      })

      if (currentSeason === -1) {
        currentSeason = 0
      }
      return { currentSeason }
    },
    {
      setCurrentSeason: () => selectedSeason => ({
        currentSeason: parseInt(selectedSeason, 10)
      })
    }
  )
)

export default enhance(SeasonsKids)
