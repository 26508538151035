import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'
import { equals, path, pathOr } from 'ramda'
import ReactSVG from 'react-svg'

import RatingsReasonsTooltip from '../../shared/ratings-tooltip'
import {
  getDetailsFullUrl,
  getSeasonRange,
  getYearRange,
  isKids,
  pluralize
} from '../../../lib/utils'

import HeroImage from '../../hero-image'
import { MoreInfoComponent as MoreInfo } from '../../shared/more-info'
import ContentVideoDisplay from '../../../containers/shared/content-video-display'
import AddOrRemoveFromListButton from '../../../containers/add-or-remove-from-list-button'
import UpcomingEpisode from '../../shared/upcoming-episode'

import styles from './series-detail-kids.css'
import arrowDown from '../../../../images/down-arrow.svg'

import CONTENT_ITEM_FRAGMENT from '../../../../graphql/fragments/content-item.gql'
import CONTENT_ITEM_LIGHT_FRAGMENT from '../../../../graphql/fragments/content-item-light.gql'
import {
  COMPONENT_NAME_SERIES_DETAIL,
  COMPONENT_NAME_SERIES_DETAIL_KIDS
} from '../../../lib/analytics/custom-dimensions'
import { openGraphMetaImages } from '../../../lib/open-graph/images'
import { getLocationBaseWithHref } from '../../../lib/location'
import { openGraphMetaActors } from '../../../lib/open-graph/actors'
import { openGraphMetaDirectors } from '../../../lib/open-graph/directors'
import { openGraphMetaTags } from '../../../lib/open-graph/tags'
import { HelmetIfNoModal } from '../../helmet-if-no-modal'
import { Rating } from '../../rating'

function SeriesDetailKids({
  contentItem,
  myList,
  theme,
  moreInfoExpanded,
  toggleMoreInfoExpanded,
  showMoreInfoButton
}) {
  const imageUri = contentItem.keyart.uri
  const genres =
    contentItem.genres &&
    contentItem.genres.slice(0, 1).map(genre => (
      <span className={styles.genre} key={genre}>
        {genre}
      </span>
    ))
  const seasonCount = pluralize(contentItem.seasons.length, 'Season', 's')

  const yearRange = getYearRange(contentItem.seasons)
  const rating = path(['rating', 'rating'], contentItem)
  const reason = pathOr('', ['rating', 'reason'], contentItem)

  const brand = 'NEON'

  const title = `Watch ${contentItem.title} Online | Season ${getSeasonRange(contentItem.seasons, ' - ')} on ${brand}`
  const description = `Watch ${contentItem.title} season ${getSeasonRange(contentItem.seasons, ' - ')} online with ${brand}.`

  const displayRating = () => {
    if (contentItem.rating.ratingSystem === 'BSV') {
      return (
        <RatingsReasonsTooltip reason={reason}>
          <span
            className={classNames({
              [styles.rating]: true,
              [styles.G]: equals('G', rating),
              [styles.PG]: equals('PG', rating)
            })}
            data-lbx-e2e={rating}
          >
            {rating}
          </span>
        </RatingsReasonsTooltip>
      )
    }

    return (
      <Rating size="medium" rating={contentItem.rating} className={styles.ratingLabel} />
    )
  }

  return (
    <div className={styles.seriesDetailKids}>
      <HelmetIfNoModal>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="video.tv_show" />
        <meta property="og:url" content={getLocationBaseWithHref()} />
        <meta
          property="og:video:release_date"
          content={contentItem.originalAirDate}
        />
        {openGraphMetaImages(contentItem.images)}
        {openGraphMetaActors(contentItem.actors)}
        {openGraphMetaDirectors(contentItem.directors)}
        {openGraphMetaTags(contentItem.genres)}
        <meta
          name="description"
          property="og:description"
          content={description}
        />
        <link rel="canonical" href={getDetailsFullUrl(contentItem)} />
        <title>{title}</title>
      </HelmetIfNoModal>

      <div className={styles.kidsHeroImageWrapper}>
        <HeroImage
          className={styles.heroImage}
          image={imageUri}
          title={contentItem.title}
          brandLogo={contentItem.brandOrDistributorLogo}
        />

        <ContentVideoDisplay
          className={styles.contentVideoWrapper}
          imageWrapperClass={styles.contentVideoImageWrapper}
          iconWrapperClass={styles.contentVideoIconWrapper}
          imageClass={styles.contentVideoImage}
          contentItem={contentItem}
          tile={contentItem.episodicTile}
          hideTime
        />
      </div>
      <div className={styles.header}>
        <div className={styles.leftHeader}>
          <h1 className={styles.title} data-lbx-e2e={contentItem.title}>
            {contentItem.title}
          </h1>
          <div className={styles.subHeader}>
            {rating && displayRating()}
            <span className={styles.genresAndSeasonCount}>{genres}</span>
            <span className={styles.genresAndSeasonCount}>
              {' '}
              |
              {' '}
              {seasonCount}
            </span>
            {yearRange && (
              <span>
                |
                {yearRange}
              </span>
            )}
          </div>
        </div>
        {contentItem.isComingSoon && contentItem.start && (
          <UpcomingEpisode startDate={contentItem.start} title />
        )}
        <div className={styles.rightHeader}>
          <div className={styles.addOrRemoveButtonWrapper}>
            <AddOrRemoveFromListButton
              className={styles.addOrRemoveButton}
              iconWrapperClassName={styles.addOrRemoveButtonIcon}
              contentItem={contentItem}
              myList={myList}
              renderedInComponent={
                isKids(theme)
                  ? COMPONENT_NAME_SERIES_DETAIL_KIDS
                  : COMPONENT_NAME_SERIES_DETAIL
              }
              theme={theme}
            />
          </div>
          {showMoreInfoButton && (
            <button
              className={classNames(styles.moreInfoButton, {
                [styles.moreInfoButtonNeon]: true
              })}
              onClick={toggleMoreInfoExpanded}
            >
              {moreInfoExpanded ? 'Less' : 'More'}
              {' '}
              Info
              <div
                className={classNames(styles.moreInfoButtonWrapper, {
                  [styles.open]: moreInfoExpanded
                })}
              >
                <ReactSVG
                  beforeInjection={svg => {
                    svg.setAttribute(
                      'class',
                      classNames(styles.moreInfoButtonIcon)
                    )
                  }}
                  src={arrowDown}
                />
              </div>
            </button>
          )}
        </div>

        <MoreInfo
          className={styles.moreInfoWrapper}
          synopsisClassName={styles.seriesSynopsis}
          showMoreInfoButton={false}
          isExpanded={moreInfoExpanded}
          contentItem={contentItem}
          theme={theme}
        />
      </div>
    </div>
  )
}

SeriesDetailKids.propTypes = {
  contentItem: propType(CONTENT_ITEM_FRAGMENT).isRequired,
  myList: PropTypes.arrayOf(propType(CONTENT_ITEM_LIGHT_FRAGMENT)).isRequired,
  theme: PropTypes.string.isRequired,
  moreInfoExpanded: PropTypes.bool.isRequired,
  toggleMoreInfoExpanded: PropTypes.func.isRequired,
  showMoreInfoButton: PropTypes.bool.isRequired
}

export default SeriesDetailKids
