import React from 'react'
import ReactSVG from 'react-svg'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classNames from 'classnames'
import { replace, path } from 'ramda'

import RatingsReasonsTooltip from '../shared/ratings-tooltip'
import HeroImage from '../hero-image'
import WatchButton from '../../containers/play-button/showpage-play-button'
import AddOrRemoveFromListButton from '../../containers/add-or-remove-from-list-button'
// TODO: TODO: LBXW-605
// import ShareButton from '../shared/share-button';
import UpcomingEpisode from '../shared/upcoming-episode'
import CastCrew from '../shared/cast-crew'
import Clamped from '../shared/clamped'
import {
  getFirstTrailer,
  getEnableAutoplayTrailer,
  getSeasonRange,
  getYearRange,
  getDetailsFullUrl,
  isKids,
  pluralize,
  getTwoGenres,
  maxDescriptionLines
} from '../../lib/utils'

import styles from './series-detail.css'

import arrowDown from '../../../images/down-arrow.svg'
import arrowUp from '../../../images/up-arrow.svg'
import arrowDownDark from '../../../images/down-arrow-dark.svg'
import arrowUpDark from '../../../images/up-arrow-dark.svg'
import CONTENT_ITEM_FRAGMENT from '../../../graphql/fragments/content-item.gql'
import CONTENT_ITEM_LIGHT_FRAGMENT from '../../../graphql/fragments/content-item-light.gql'
import { openGraphMetaImages } from '../../lib/open-graph/images'
import { openGraphMetaActors } from '../../lib/open-graph/actors'
import { openGraphMetaTags } from '../../lib/open-graph/tags'
import { openGraphMetaDirectors } from '../../lib/open-graph/directors'
import {
  getLocationBaseWithHref
} from '../../lib/location'
import { navigationGa } from '../../lib/analytics/ga'
import { PLAY_BUTTON_IN_SERIES_HERO } from '../../containers/play-button/constants'
import {
  COMPONENT_NAME_SERIES_DETAIL,
  COMPONENT_NAME_SERIES_DETAIL_KIDS
} from '../../lib/analytics/custom-dimensions'
import { HelmetIfNoModal } from '../helmet-if-no-modal'
import { E2E_SELECTORS } from '../../e2e-selectors'
import { SCREEN_SIZE_BREAKPOINTS, THEME_OPTIONS } from '../../constants'
import AutoplayTrailerStream from '../autoplay-trailer/autoplay-trailer-stream'
import { RatingDetails } from '../ratingDetails'
import { LeavingSoonTitle } from '../shared/leaving-soon-title'

function SeriesDetail({
  contentItem,
  account,
  myList,
  theme,
  rating,
  reason,
  advisories,
  screenWidth,
  isExpanded,
  onReadMoreClick,
  episodeToPlay
}) {
  const descriptionLines = maxDescriptionLines(screenWidth)
  const showReadMoreButton = screenWidth <= SCREEN_SIZE_BREAKPOINTS.MIN_768
  const upIcon = theme === THEME_OPTIONS.light ? arrowUpDark : arrowUp
  const downIcon = theme === THEME_OPTIONS.light ? arrowDownDark : arrowDown
  const linkIcon = isExpanded ? upIcon : downIcon
  const linkText = isExpanded ? 'READ LESS' : 'READ MORE'
  const isCVODRating = contentItem.rating?.ratingSystem === 'CVOD'

  // as per design show only 2 first genres
  const truncatedGenres = getTwoGenres(contentItem)
  const genres = truncatedGenres && truncatedGenres.map(genre => (
    <span className={styles.genre} key={genre}>{genre}</span>
  ))
  const seasonCount = pluralize(contentItem.seasons.length, 'SEASON', 'S')

  const yearRange = getYearRange(contentItem.seasons)
  const editedYearRange = yearRange && yearRange.length <= 6 ? replace(/[()]/g, '', yearRange) : yearRange

  const imageUri = contentItem.keyart && contentItem.keyart.uri
  const enableAutoplayTrailers = getEnableAutoplayTrailer(contentItem)
  const trailer = getFirstTrailer(enableAutoplayTrailers)

  const brand = 'NEON'

  const title = `Watch ${contentItem.title} Online | Season ${getSeasonRange(contentItem.seasons, ' - ')} on ${brand}`
  const description = `Watch ${contentItem.title} season ${getSeasonRange(contentItem.seasons, ' - ')} online with ${brand}.`

  // do not show auto trailer when user is not logged in
  const turnOffTrailerAutoplay = account?.settings?.turnOffTrailerAutoplay ?? true

  const displayCVODRating = () => {
    return (
      <RatingDetails ratingContent={contentItem.rating} reason={contentItem.rating.reason} />
    )
  }

  const displayBSVRating = () => {
    return (
      <RatingsReasonsTooltip
        reason={reason}
      >
        <span
          className={classNames(styles.rating, styles.seriesRating)}
          data-lbx-e2e="Rating"
        >
          {`${rating} ${advisories}`}
        </span>
      </RatingsReasonsTooltip>
    )
  }

  const displayIsComingSoon = () => {
    // if series isComingSoon = true, display COMING SOON and use series start as Available date
    if (contentItem.isComingSoon && contentItem.start) {
      return (
        <UpcomingEpisode
          startDate={contentItem.start}
          title
        />
      )
    }

    const seasonIsComingSoon = path(['seasons', 0, 'isComingSoon'], contentItem)

    /*
      if total season is 1, and season isComingSoon = true,
      display COMING SOON and use season start as Available date
    */
    if (contentItem.seasons?.length === 1 && seasonIsComingSoon) {
      const seasonStartDate = contentItem.seasons[0].start
      return (
        <UpcomingEpisode
          startDate={seasonStartDate}
          title
        />
      )
    }

    return null
  }

  const displayIsLeavingSoon = () => {
    /* LA30-2200: If comingSoon and leavingSoon = true, ignore leaving soon and only display coming soon */
    if (
      contentItem.isLeavingSoon &&
      !contentItem.isComingSoon &&
      contentItem.end
    ) {
      return (
        <LeavingSoonTitle
          endDate={contentItem.end}
          isRental={contentItem.isRental}
        />
      )
    }
    return null
  }

  return (
    <div>
      <div className={styles.seriesDetail}>
        <HelmetIfNoModal>
          <meta property="og:title" content={title} />
          <meta property="og:type" content="video.tv_show" />
          <meta property="og:url" content={getLocationBaseWithHref()} />
          <meta property="og:video:release_date" content={contentItem.originalAirDate} />
          {openGraphMetaImages(contentItem.images)}
          {openGraphMetaActors(contentItem.actors)}
          {openGraphMetaDirectors(contentItem.directors)}
          {openGraphMetaTags(contentItem.genres)}
          <meta
            name="description"
            property="og:description"
            content={description}
          />
          <link rel="canonical" href={getDetailsFullUrl(contentItem)} />
          <title>{title}</title>
        </HelmetIfNoModal>
        {
          !turnOffTrailerAutoplay && trailer ? (
            <AutoplayTrailerStream content={trailer} image={imageUri} />
          ) : (
            <HeroImage
              image={imageUri}
              title={contentItem.title}
              aspectRatio="16:8"
              brandLogo={contentItem.brandOrDistributorLogo}
            />
          )
        }
        <div className={styles.header}>
          <div className={styles.leftHeader}>
            <h1 className={styles.title} data-lbx-e2e={E2E_SELECTORS.SHOW_TITLE}>
              {contentItem.title}
            </h1>
            <div className={styles.ratingFrame}>
              {
                rating && isCVODRating && displayCVODRating()
              }
            </div>
            <div className={styles.subHeader}>
              {
                rating && !isCVODRating && displayBSVRating()
              }
              <div
                className={styles.genresAndSeasonRange}
                data-lbx-e2e="Genre"
              >
                {
                  genres
                }
                <span data-lbx-e2e="Available Seasons">{seasonCount}</span>
                {yearRange
                  ? <span>{editedYearRange}</span>
                  : null}
              </div>
            </div>

            { displayIsComingSoon() }

            { displayIsLeavingSoon() }

            <div className={styles.buttonsWrapper}>
              <WatchButton
                displayedInLocation={PLAY_BUTTON_IN_SERIES_HERO}
                contentItem={contentItem}
              />
              <AddOrRemoveFromListButton
                contentItem={contentItem}
                myList={myList}
                renderedInComponent={
                  isKids(theme) ? COMPONENT_NAME_SERIES_DETAIL_KIDS : COMPONENT_NAME_SERIES_DETAIL
                }
                ctaAdd="MY LIST"
                ctaRemove="MY LIST"
                theme={theme}
              />
              {/*
                TODO: LBXW-605
                <ShareButton id={contentItem.id} />
              */}
            </div>
            {
              contentItem.description && (
                <div className={styles.description}>
                  {
                    (!showReadMoreButton || (showReadMoreButton && isExpanded))
                      ? <Clamped lines={descriptionLines}>{contentItem.description}</Clamped>
                      : <Clamped lines={3}>{contentItem.description}</Clamped>
                  }
                </div>
              )
            }
            {
              (!showReadMoreButton || (showReadMoreButton && isExpanded)) && theme !== 'LIGHTBOX_KIDS' && (
                <CastCrew
                  contentItem={contentItem}
                  theme={theme}
                  screenWidth={screenWidth}
                />
              )
            }
            {
              showReadMoreButton && (
                <div
                  className={styles.linkWrapper}
                >
                  <div
                    className={classNames(styles.link, {
                      [styles.isRental]: contentItem.isRental
                    })}
                    onClick={onReadMoreClick}
                  >
                    <span
                      className={styles.linkText}
                    >
                      {linkText}
                    </span>
                    <ReactSVG
                      src={linkIcon}
                      beforeInjection={(svg) => {
                        svg.setAttribute('class', styles.linkIcon)
                      }}
                    />
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

SeriesDetail.propTypes = {
  contentItem: propType(CONTENT_ITEM_FRAGMENT).isRequired,
  account: PropTypes.shape({
    settings: PropTypes.shape({
      turnOffTrailerAutoplay: PropTypes.bool.isRequired
    })
  }).isRequired,
  myList: PropTypes.arrayOf(propType(CONTENT_ITEM_LIGHT_FRAGMENT)).isRequired,
  theme: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  advisories: PropTypes.string.isRequired,
  screenWidth: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onReadMoreClick: PropTypes.func,
  episodeToPlay: PropTypes.shape({
    id: PropTypes.string
  })
}

SeriesDetail.defaultProps = {
  navigationGaFunction: navigationGa,
  onReadMoreClick: () => { }
}

export default SeriesDetail
