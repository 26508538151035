import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './seasons.css'

import {
  slipnslideCalcSeasonShowCount
} from '../../lib/utils'

import SlipNSlide from '../../../lib/slip-n-slide'

function SeasonSlide({
  seasons, seasonSelected, setSeasonSelected, screenWidth
}) {
  const slidesToShow = slipnslideCalcSeasonShowCount(screenWidth, 1)

  return (
    <div
      className={styles.wrapper}
    >
      <SlipNSlide
        margin={0}
        duration={slidesToShow * 180}
        slidesToShow={slidesToShow}
        arrowClass={styles.blackArrow}
      >
        {
          seasons.map(season => {
            return (
              <div
                key={season.id}
                className={classNames(
                  styles.season,
                  {
                    [styles.seasonActive]: seasonSelected === season.seasonNumber
                  }
                )}
                onClick={() => setSeasonSelected(season.seasonNumber)}
              >
                {`Season ${season.seasonNumber}`}
              </div>
            )
          })
        }
      </SlipNSlide>
    </div>
  )
}

SeasonSlide.propTypes = {
  seasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      seasonNumber: PropTypes.number
    }).isRequired
  ).isRequired,
  seasonSelected: PropTypes.number.isRequired,
  setSeasonSelected: PropTypes.func.isRequired,
  screenWidth: PropTypes.number
}

SeasonSlide.defaultProps = {
  screenWidth: window.innerWidth
}

export default SeasonSlide
