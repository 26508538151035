import { map, length, propOr } from 'ramda'
import { getDetailsFullUrl } from '../utils'
import { image } from './image'
import { getPersonList } from './person'
import { hasPart } from './hasPart'
import { potentialAction } from './potentialAction'
import { releasedEvent } from './releasedEvent'

export const createSeriesLDSchema = (series) => {
  const sameAsUrl = series.socialLinks?.imdbUrl
  return {
    '@id': getDetailsFullUrl(series),
    name: series.title,
    url: getDetailsFullUrl(series),
    description: series.description,
    image: image(series.images),
    inLanguage: series.language,
    dateCreated: series.originalAirDate,
    sameAs: sameAsUrl,
    director: getPersonList(series.directors),
    actor: getPersonList(series.actors),
    numberOfSeasons: length(series.seasons),
    containsSeason: map(season => ({
      '@id': getDetailsFullUrl(season),
      '@type': 'TVSeason',
      url: getDetailsFullUrl(season),
      releasedEvent: releasedEvent(season.year, series.country),
      seasonNumber: season.seasonNumber,
      partOfSeries: {
        '@type': 'TVSeries',
        name: series.title,
        sameAs: sameAsUrl,
        url: getDetailsFullUrl(series)
      },
      potentialAction: potentialAction(season, series.products, series.language),
      // hasPart: hasPart(season.videoExtras, series.language, 'TVClip'),
      numberOfEpisodes: length(season.episodes),
      episodes: map(episode => ({
        '@id': getDetailsFullUrl(episode),
        '@type': 'TVEpisode',
        url: getDetailsFullUrl(episode),
        episodeNumber: episode.episodeNumber,
        name: episode.title,
        director: getPersonList(episode.directors),
        actor: getPersonList(episode.actors),
        partOfSeason: {
          '@type': 'TVSeason',
          seasonNumber: season.seasonNumber
        },
        partOfSeries: {
          '@type': 'TVSeries',
          name: series.title,
          sameAs: sameAsUrl,
          url: getDetailsFullUrl(series)
        },
        releasedEvent: releasedEvent(episode.originalAirDate, series.country),
        potentialAction: potentialAction(episode, series.products, series.language),
        hasPart: hasPart(episode.videoExtras, series.language, 'TVClip')
      }), propOr([], 'episodes', season))
    }), propOr([], 'seasons', series)),
    releasedEvent: releasedEvent(series.originalAirDate, series.country),
    potentialAction: potentialAction(series, series.products, series.language),
    hasPart: hasPart(series.videoExtras, series.language, 'TVClip')
  }
}
