import React from 'react'
import PropTypes from 'prop-types'

import ReactSVG from 'react-svg'

import arrowDown from '../../../images/dropdown-triangle-down.svg'
import arrowUp from '../../../images/dropdown-triangle-up.svg'

import styles from './arrow-season-dropdown.css'

function Arrow({
  isOpen
}) {
  return (
    <ReactSVG
      src={isOpen ? arrowUp : arrowDown}
      beforeInjection={(svg) => {
        svg.setAttribute('class', styles.selectorArrow)
      }}
    />
  )
}

Arrow.propTypes = {
  isOpen: PropTypes.bool.isRequired
}

export default Arrow
