import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import classNames from 'classnames'

import {
  find, propEq, lensProp, set
} from 'ramda'

import styles from './selector.css'

import AsyncReactSelect from '../../../../containers/async-react-select'

import tick from '../../../../../images/season-selected.svg'
import Arrow from '../../../shared/arrow-season-dropdown'
import {
  customReactSelectStyles,
  customReactSelectTheme
} from '../../../forms/select-custom-syles'

const labelLens = lensProp('dropdownLabel')
const SELECT_VALUE_KEY = 'dropdownLabel'

function DropdownSelectorKids({ seasons, seasonSelected, setSeasonSelected }) {
  const seasonsWithDropdownLabel = seasons.map(season => set(labelLens, season.name, season))
  const selectedOption = find(propEq('value', seasonSelected))(
    seasonsWithDropdownLabel
  )

  return (
    <div className={styles.dropdownWrapper}>
      <AsyncReactSelect
        styles={customReactSelectStyles({ isKid: true, width: 160 })}
        theme={customReactSelectTheme({ isKid: true })}
        value={selectedOption}
        labelKey={SELECT_VALUE_KEY}
        formatOptionLabel={(data, { context }) => {
          return (
            <div
              key={data.value}
              className={classNames(styles.listedSeasonKids, {
                [styles.selectedSeasonKids]: data.value === seasonSelected
              })}
            >
              {context === 'menu' && (
                <ReactSVG
                  src={tick}
                  beforeInjection={svg => {
                    svg.setAttribute(
                      'class',
                      classNames(styles.hide, {
                        [styles.tick]: data.value === seasonSelected
                      })
                    )
                  }}
                />
              )}
              {data.name}
            </div>
          )
        }}
        options={seasonsWithDropdownLabel}
        onChange={season => setSeasonSelected(season.value)}
        arrowRenderer={Arrow}
        isSearchable={false}
        isClearable={false}
        backspaceRemovesValue={false}
      />
    </div>
  )
}

DropdownSelectorKids.propTypes = {
  seasons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number
    }).isRequired
  ).isRequired,
  seasonSelected: PropTypes.number.isRequired,
  setSeasonSelected: PropTypes.func.isRequired
}

export default DropdownSelectorKids
