import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'
import { T, F } from 'ramda'

import SeriesDetail from '../../containers/series/series-detail'
import SeriesList from '../../containers/series/series-list'
import Reviews from '../shared/reviews'
import BackToTop from '../shared/back-to-top'
import { THEME_OPTIONS, SCREEN_SIZE_BREAKPOINTS } from '../../constants'

import SERIES_COMPONENT_FRAGMENT from '../../../graphql/fragments/series-component.gql'

import { JSONLD } from '../jsonld/index'
import { createSeriesLDSchema } from '../../lib/jsonld/series'
import SeasonsKids from './kids/seasons-kids'
import styles from './series.css'

function Series({
  component, theme, screenWidth, addToMyList, removeFromMyList, isContentItemOnMyList
}) {
  const { series } = component
  const isKids = theme === THEME_OPTIONS.light
  return (
    <>
      <SeriesDetail contentItem={series} theme={theme} screenWidth={screenWidth} />
      {
        !isKids && <Reviews reviews={series.ratingsAndAwards} />
      }
      <div className={classNames({
        [styles.columnsWrapper]: !isKids
      })}
      >
        {
          isKids
            ? <SeasonsKids series={series} seasons={series.seasons} />
            : (
              <SeriesList
                series={series}
                addToMyList={addToMyList}
                removeFromMyList={removeFromMyList}
                isContentItemOnMyList={isContentItemOnMyList}
                theme={theme}
              />
            )
        }
      </div>
      {
        screenWidth > SCREEN_SIZE_BREAKPOINTS.MIN_480 && <BackToTop />
      }
      <JSONLD schema={createSeriesLDSchema(series)} type="TVSeries" />
    </>
  )
}

Series.propTypes = {
  component: propType(SERIES_COMPONENT_FRAGMENT).isRequired,
  theme: PropTypes.string.isRequired,
  screenWidth: PropTypes.number.isRequired,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  isContentItemOnMyList: PropTypes.func
}

Series.defaultProps = {
  addToMyList: T,
  removeFromMyList: T,
  isContentItemOnMyList: F
}

export default Series
