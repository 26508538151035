import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  graphql,
  withApollo
} from 'react-apollo'
import {
  branch,
  compose,
  defaultProps,
  lifecycle,
  renderComponent,
  renderNothing,
  setPropTypes,
  withProps,
  withState,
  withHandlers
} from 'recompose'

import {
  pathOr,
  flatten,
  pluck,
  propEq,
  find
} from 'ramda'
import { connect } from 'react-redux'

import {
  getContentParentalRatingDisplay,
  isKids
} from '../../lib/utils'
import { checkIsAuthenticated } from '../../lib/auth'
import SeriesDetail from '../../components/series/series-detail'
import SeriesDetailKids from '../../components/series/kids/series-detail-kids'
import addToMyListQS from '../../hoc/qs-add-to-my-list'
import withInitializeCastSender from '../../hoc/with-castPlayer-sender-initialize'
import withCastSender from '../../hoc/with-cast-sender'
// TODO: Remove feature flag
import withFeatureFlag from '../../hoc/with-feature-flag'

import MY_LIST_QUERY from '../../../graphql/queries/my-list.gql'
import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'
import { withCurrentProfile } from '../../hoc/with-current-profile'
import {
  dataLayerProductDetail,
  getAdditionalGenreFromContentItem
} from '../../lib/analytics/datalayer'
import {
  episodeHouseId,
  navigationGa,
  seasonHouseId,
  seriesTitleHouseId
} from '../../lib/analytics/ga'
import {
  COMPONENT_NAME_SERIES_DETAIL,
  COMPONENT_NAME_SERIES_DETAIL_KIDS,
  customDimensions
} from '../../lib/analytics/custom-dimensions'
import withMoreInfoExpandState from '../../hoc/with-more-info-expand-state'

const mapStateToProps = state => ({
  isAuthenticated: checkIsAuthenticated(state),
  theme: state.theme
})

export const enhance = compose(
  connect(mapStateToProps),
  // TODO: Remove feature flag
  withFeatureFlag('series_showpage'),
  withCastSender,
  withInitializeCastSender,
  withMoreInfoExpandState,
  setPropTypes({
    dataLayerProductDetailFunction: PropTypes.func,
    navigationGaFunction: PropTypes.func
  }),
  defaultProps({
    dataLayerProductDetailFunction: dataLayerProductDetail,
    navigationGaFunction: navigationGa
  }),
  withRouter,
  withApollo,
  withCurrentProfile,
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated
  }),
  graphql(MY_LIST_QUERY, {
    name: 'myListQuery',
    skip: ownProps => !ownProps.isAuthenticated,
    options: {
      notifyOnNetworkStatusChange: true
    }
  }),
  branch(
    ({ myListQuery, playbackInfoQuery }) => (
      (myListQuery && (myListQuery.loading || myListQuery.error)) ||
      (playbackInfoQuery && (playbackInfoQuery.loading || playbackInfoQuery.error))
    ),
    renderNothing
  ),
  withProps(({
    myListQuery,
    playbackInfoQuery,
    accountQuery,
    contentItem
  }) => {
    return ({
      myList: pathOr([], ['mylist', 'items'], myListQuery),
      playbackInfo: pathOr(['playbackInfo'], playbackInfoQuery),
      rating: getContentParentalRatingDisplay(contentItem),
      reason: pathOr('', ['rating', 'reason'], contentItem),
      advisories: pathOr('', ['rating', 'advisories'], contentItem),
      account: accountQuery?.account,
      episodeToPlay: find(propEq('isComingSoon', false), flatten(pluck('episodes', pathOr([], ['seasons'], contentItem))))
    })
  }),
  addToMyListQS,
  lifecycle({
    componentDidMount() {
      const {
        contentItem,
        dataLayerProductDetailFunction,
        navigationGaFunction,
        currentProfile,
        theme,
        castPreviewPageSender
      } = this.props
      castPreviewPageSender(contentItem)
      dataLayerProductDetailFunction(contentItem)
      navigationGaFunction({
        action: 'showPage',
        label: contentItem.title,
        [customDimensions.ShowTitle]: contentItem.title,
        [customDimensions.ProfileId]: currentProfile && currentProfile.id,
        [customDimensions.ProfileName]: currentProfile && currentProfile.name,
        [customDimensions.SeasonNumber]: contentItem.seasonNumber,
        [customDimensions.EpisodeNumber]: contentItem.episodeNumber,
        [customDimensions.AdditionalGenre]: getAdditionalGenreFromContentItem(contentItem),
        [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(contentItem),
        [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
        [customDimensions.EpisodeHouseId]: episodeHouseId(contentItem),
        [customDimensions.ComponentName]: isKids(theme)
          ? COMPONENT_NAME_SERIES_DETAIL_KIDS : COMPONENT_NAME_SERIES_DETAIL
      })
    }
  }),
  withState('isExpanded', 'setIsExpanded', false),
  withHandlers({
    onReadMoreClick: ({ isExpanded, setIsExpanded }) => () => {
      setIsExpanded(!isExpanded)
    }
  }),
  branch(
    ({ theme }) => isKids(theme),
    renderComponent(SeriesDetailKids),
    renderComponent(SeriesDetail)
  )
)

export default enhance()
