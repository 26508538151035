import React from 'react'
import PropTypes from 'prop-types'

import styles from './selector.css'
import { withScreenWidthWatching } from '../../../../hoc/with-screen-width-watching'

import DropdownSelectorKids from './dropdown-selector-kids'
import LinkSelectorKids from './link-selector-kids'

function Selector({ screenWidth, ...props }) {
  const { seasons } = props
  const eachTabWidth = 112 // approximated value based on observation
  return (
    <div className={styles.wrapper}>
      {
        screenWidth - 100 < eachTabWidth * seasons.length
          ? <DropdownSelectorKids {...props} />
          : <LinkSelectorKids {...props} />
      }
    </div>
  )
}

Selector.propTypes = {
  screenWidth: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  seasons: PropTypes.array.isRequired
}

export default withScreenWidthWatching(Selector)
