import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import ReactSVG from 'react-svg'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { pathOr } from 'ramda'

import Imgix from '../imgix'
import PlayButton from '../../containers/play-button/details-play-button'
import { formatParagraphs } from '../../lib/content-item'
import { getTimeLeftString } from '../../containers/play-button/utils'
import styles from './episode.css'

import { PLAY_BUTTON_IN_SERIES_EPISODE_SELECTOR } from '../../containers/play-button/constants'
import { SCREEN_SIZE_BREAKPOINTS } from '../../constants'
import UpcomingEpisode from '../shared/upcoming-episode'
import arrowDown from '../../../images/down-arrow.svg'
import arrowUp from '../../../images/up-arrow.svg'

function Episode({
  episode, playbackInfo, highlight, isExpanded, onMoreInfoClick, screenWidth, watchUrl
}) {
  // looks like below change has caused DE displaying wrong episodic image,
  // because loading dock flow doesn't update the tile image when the episodic image is updated
  // const imageUri = pathOr('', ['tile', 'image'], episode)
  const imageUri = pathOr('', ['images', '0', 'uri'], episode)
  const linkIcon = isExpanded ? arrowUp : arrowDown
  const linkText = isExpanded ? 'LESS INFO' : 'MORE INFO'
  const isSamllScreen = screenWidth <= SCREEN_SIZE_BREAKPOINTS.MIN_360
  const time = getTimeLeftString(playbackInfo.playbackInfo, true)
  const isComingSoon = episode.upcoming || episode.isComingSoon
  const history = useHistory()

  const handleOnClick = useCallback(() => {
    if (!isComingSoon && watchUrl && playbackInfo?.playbackInfo?.watchable) {
      history.push(watchUrl)
    }
  }, [watchUrl])

  return (
    <div
      className={classNames(
        styles.episode,
        {
          [styles.highlight]: highlight,
          [styles.clickable]: !isComingSoon
        }
      )}
      id={`episode-${episode.id}`}
      onClick={handleOnClick}
    >
      <div
        className={classNames(
          styles.imageWrapper,
          { [styles.imgOverlay]: isComingSoon }
        )}
      >
        <Imgix
          className={styles.image}
          src={imageUri}
          recipe="series"
        />
        {
          isComingSoon
            ? (
              <UpcomingEpisode
                startDate={episode.start}
              />
            )
            : (
              <div // eslint-disable-line jsx-a11y/no-static-element-interactions
                className={styles.playWrapper}
              >
                <div className={classNames(styles.ctaContainer)}>
                  <PlayButton
                    className={styles.smallPlay}
                    contentItem={episode}
                    contentItemId={episode.id}
                    playbackInfo={playbackInfo}
                    displayedInLocation={PLAY_BUTTON_IN_SERIES_EPISODE_SELECTOR}
                  />
                </div>
              </div>
            )
        }
      </div>
      <div className={styles.textAndMetadata}>
        <div className={styles.text}>
          <div className={styles.episodeTitle}>
            <span>
              {episode.episodeNumber}
              .
              {' '}
              {episode.title}
            </span>
          </div>
          <p className={styles.description}>
            {formatParagraphs(episode.description || episode.summary)}
          </p>
          <div
            className={classNames(
              styles.moreInfoDescription,
              { [styles.smallBtn]: isSamllScreen }
            )}
          >
            <div
              className={styles.linkWrapper}
            >
              <div
                className={styles.link}
                onClick={onMoreInfoClick}
              >
                {!isSamllScreen && (
                  <span
                    className={styles.linkText}
                  >
                    {linkText}
                  </span>
                )}
                <ReactSVG
                  src={linkIcon}
                  beforeInjection={(svg) => {
                    svg.setAttribute('class', styles.linkIcon)
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.time}>
            {time}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          styles.descriptionExpanded,
          { [styles.showExpanded]: isExpanded }
        )}
      >
        <p>
          {formatParagraphs(episode.description || episode.summary)}
        </p>
      </div>
    </div>
  )
}

Episode.propTypes = {
  episode: PropTypes.shape({
    id: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string
      })
    ),
    upcoming: PropTypes.bool,
    isComingSoon: PropTypes.bool,
    start: PropTypes.string,
    episodeNumber: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    summary: PropTypes.string,
    duration: PropTypes.number
  }).isRequired,
  watchUrl: PropTypes.string.isRequired,
  playbackInfo: PropTypes.shape({ status: PropTypes.string, playbackInfo: PropTypes.oneOfType([PropTypes.object]) }),
  highlight: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  onMoreInfoClick: PropTypes.func,
  screenWidth: PropTypes.number
}

Episode.defaultProps = {
  playbackInfo: null,
  highlight: false,
  onMoreInfoClick: () => { },
  screenWidth: window.innerWidth
}

export default Episode
