import React from 'react'
import PropTypes from 'prop-types'
import { Background } from 'react-imgix'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { compose, withProps } from 'recompose'

import styles from './episode.css'
import Progress from './progress'
import PlayButton from './play-button'
import Clamped from '../../../shared/clamped'

import { formatParagraphs } from '../../../../lib/content-item'
import { dataLayerPlay } from '../../../../lib/analytics/datalayer'
import withCastSender from '../../../../hoc/with-cast-sender'
import withInitializeCastSender from '../../../../hoc/with-castPlayer-sender-initialize'
import { getWatchUrl } from '../../../../lib/utils'

const IN_PROGRESS = 'IN_PROGRESS'

function Episode({
  episode,
  playback,
  isSelected,
  onClick
}) {
  const {
    images, title, description, episodeNumber, duration, summary
  } = episode
  const imageWidth = 600
  const imageRatio = 9 / 16
  const isInProgress = playback.status === IN_PROGRESS
  const played = playback.numberMinutesWatched
  const total = playback.numberMinutesWatched + playback.numberMinutesRemaining
  return (
    <Link
      id={episode.id}
      data-lbx-e2e={`episodeNo${episodeNumber}. ${title}`}
      className={styles.wrapper}
      to={`/watch/${episode.id}`}
      onClick={onClick}
    >
      <Background
        src={images[0].uri}
        className={styles.image}
        width={imageWidth}
        height={imageWidth * imageRatio}
      >
        <PlayButton
          className={classnames({
            [styles.notSelected]: !isSelected
          })}
        />
        {isInProgress && (
          <Progress
            played={played}
            total={total}
          />
        )}
      </Background>
      <div className={styles.infoWrapper}>
        <h3 className={styles.title}>
          <Clamped lines={2}>{`${episodeNumber}. ${title}`}</Clamped>
        </h3>
        <div className={styles.duration}>
          {duration}
          {' '}
          mins
        </div>
        <div className={styles.description}>
          {formatParagraphs(description || summary)}
        </div>
      </div>
    </Link>
  )
}

Episode.propTypes = {
  episode: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.shape({
      uri: PropTypes.string.isRequired
    }).isRequired).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    episodeNumber: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    summary: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  playback: PropTypes.shape({
    status: PropTypes.string,
    numberMinutesWatched: PropTypes.number,
    numberMinutesRemaining: PropTypes.number
  }),
  onClick: PropTypes.func.isRequired
}

// LBX-475
Episode.defaultProps = {
  playback: {
    status: null,
    numberMinutesWatched: 0
  },
  episode: {
    description: ''
  }
}

const enhance = compose(
  withCastSender,
  withInitializeCastSender,
  withProps(({
    episode,
    castSender,
    maybeInitializeCastSender,
    isWatchable
  }) => ({
    onClick: (event) => {
      dataLayerPlay(episode)
      if (!castSender.isConnected) {
        return
      }

      if (isWatchable) {
        event.preventDefault()
        maybeInitializeCastSender(getWatchUrl(episode), episode)
      }
    }
  }))
)

export default enhance(Episode)
