import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import classnames from 'classnames'

import styles from './play-button.css'
import kidsPlay from '../../../../../images/play-kids.svg'

function PlayButton({ className }) {
  return (
    <div className={classnames(styles.wrapper, className)}>
      <ReactSVG
        src={kidsPlay}
        beforeInjection={(svg) => {
          svg.setAttribute('class', styles.button)
        }}
      />
    </div>
  )
}

PlayButton.propTypes = {
  className: PropTypes.string
}

PlayButton.defaultProps = {
  className: ''
}

export default PlayButton
