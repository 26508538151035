import { connect } from 'react-redux'
import {
  branch,
  compose,
  renderComponent,
  setDisplayName,
  withProps,
  withState,
  withHandlers
} from 'recompose'
import { withRouter } from 'react-router-dom'
import {
  isEmpty,
  match,
  not
} from 'ramda'

import withTheme from '../../hoc/with-theme'
import { withScreenWidthWatching } from '../../hoc/with-screen-width-watching'
import Episode from '../../components/series/episode'
import EpisodeMobile from '../../components/series/episode-mobile'

import withCastSender from '../../hoc/with-cast-sender'
import withInitializeCastSender from '../../hoc/with-castPlayer-sender-initialize'
import { withCurrentProfile } from '../../hoc/with-current-profile'
import { checkIsAuthenticated } from '../../lib/auth'
import { getModalLocation } from '../../lib/modal'
import { MODALS } from '../../constants'
import { getLocationBaseWithHref } from '../../lib/location'
import { navigationGa } from '../../lib/analytics/ga'

const mapStateToProps = (state) => {
  const isAuthenticated = checkIsAuthenticated(state)
  const isPlayer = not(isEmpty(match(/\/watch\//, getLocationBaseWithHref())))
  return {
    isAuthenticated,
    theme: state.theme,
    isPlayer
  }
}

const enhance = compose(
  setDisplayName('EpisodeContainer'),
  withRouter,
  withTheme,
  withInitializeCastSender,
  withCastSender,
  withCurrentProfile,
  connect(mapStateToProps),
  withState('isExpanded', 'setIsExpanded', false),
  withHandlers({
    onMoreInfoClick: ({ isExpanded, setIsExpanded }) => () => {
      navigationGa({ action: isExpanded ? 'LESS INFO' : 'MORE INFO' })
      setIsExpanded(!isExpanded)
    }
  }),
  withProps(({
    episode,
    isAuthenticated
  }) => {
    // To address the necessary changes for deep linking, I'm just kludging this part
    // by replacing history.push with DeepLink, keeping the logic that was originally here
    // for generating the watch link, and getting rid of the previous handler altogether
    // since EpisodeKids was the only view using it.
    let watchUrl = '#'
    if (isAuthenticated) {
      watchUrl = `/watch/${episode.id}`
    } else {
      watchUrl = getModalLocation(location, MODALS.qsParams.signup)
    }

    return {
      watchUrl
    }
  }),
  withScreenWidthWatching,
  compose(
    withScreenWidthWatching,
    branch(
      ({ isPlayer }) => isPlayer,
      renderComponent(EpisodeMobile),
      renderComponent(Episode)
    )
  )
)

export default enhance()
